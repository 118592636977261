import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import MuiAlert from "@mui/material/Alert";
import { io } from "socket.io-client";
const socket = io("https://strapi.canh7antt8a.online", {});

const Alert = MuiAlert;
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useLocalStorage } from '../hooks/useLocalStorage'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

import { useAuth } from '../hooks/useAuth'
import { useEffect } from "react";
export const ProfilePage = () => {
  const [isGo88, setIsGo88] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [kichHoatTaiXiuThuong, setKichHoatTaiXiuThuong] = React.useState(false);
  const [kichHoatTaiXiuVip, setKichHoatTaiXiuVip] = React.useState(false);
  const [userDataFromInit] = useLocalStorage("user", {});
  const [userData, setUserData] = React.useState(userDataFromInit)
  const [userLogin] = useLocalStorage("userLogin", {});

  const [showHackTien, setShowHackTien] = React.useState(false)
  const [showHackTienCheck, setShowHackTienCheck] = React.useState(false)
  const [showHack, setShowHack] = React.useState(false)
  const [showHackVip, setShowHackVip] = React.useState(false)
  const [showHackTienVip, setShowHackTienVip] = React.useState(false)
  const [showHackTienVipCheck, setShowHackTienVipCheck] = React.useState(false)
  const [userName, setUserName] = React.useState("")
  const [money, setMoney] = React.useState("")
  const { checkLogin } = useAuth()
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const go88 = localStorage.getItem("game")
    if (go88 == 'go88') {
      setIsGo88(true)
    } else {
      setIsGo88(false)
    }
  }, [])

  React.useEffect(() => {
    if (userLogin?.email && userLogin.password) {
      var check = setInterval(() => {
        checkLogin(userLogin, (result) => {
          setUserData(result)
          if (!result?.user?.kichHoat || !result?.user?.kichHoatTaiXiuThuong) {
            if (isGo88) {
              socket.off("message:create")
              socket.off("message:update")
              socket.off("thong-bao-phien:create")
              socket.off("thong-bao-phien:update")

            } else {
              socket.off("message-tx-hit:create")
              socket.off("message-tx-hit:update")
              socket.off("thong-bao-phien-hit:create")
              socket.off("thong-bao-phien-hit:update")
            }

          }

          if (!result?.user?.kichHoat || !result?.user?.kichHoatTaiXiuVip) {
            if (isGo88) {
              socket.off("message-tx-vip:create")
              socket.off("message-tx-vip:update")
              socket.off("thong-bao-phien-vip:create")
              socket.off("thong-bao-phien-vip:update")
            } else {
              socket.off("message-tx-hit-vip:create")
              socket.off("message-tx-hit-vip:update")
              socket.off("thong-bao-phien-hit-vip:create")
              socket.off("thong-bao-phien-hit-vip:update")
            }

          }

        })
      }, 5000)
    }
    return () => {
      if (check) {
        clearInterval(check)
      }
    }

  }, [userLogin, socket, isGo88])

  useEffect(() => {
    console.log("userData", userData)
  }, [userData])


  React.useEffect(() => {
    if (isGo88) {
      if (kichHoatTaiXiuThuong) {
        socket.on("message:create", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("create", data);
          const message = `| BÀN : TÀI XỈU THƯỜNG | PHIÊN #${data.Phien} | DỰ ĐOÁN : ${data.KetQua} ${data.TyLe} % |`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("message:update", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("update", data);
          const message = `| BÀN : TÀI XỈU THƯỜNG | PHIÊN #${data.Phien} | DỰ ĐOÁN : ${data.KetQua} ${data.TyLe} % |`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("thong-bao-phien:create", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("create", data);
          const message = `${data.message}`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("thong-bao-phien:update", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("update", data);
          const message = `${data.message}`;
          await setMessage(message);
          await setOpen(true);
        });
      }
      if (kichHoatTaiXiuVip) {
        socket.on("message-tx-vip:create", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("create", data);
          const message = `| BÀN : TÀI XỈU MD5 | PHIÊN #${data.Phien} | DỰ ĐOÁN : ${data.KetQua} ${data.TyLe} % |`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("message-tx-vip:update", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("update", data);
          const message = `| BÀN : TÀI XỈU MD5 | PHIÊN #${data.Phien} | DỰ ĐOÁN : ${data.KetQua} ${data.TyLe} % |`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("thong-bao-phien-vip:create", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("create", data);
          const message = `${data.message}`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("thong-bao-phien-vip:update", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("update", data);
          const message = `${data.message}`;
          await setMessage(message);
          await setOpen(true);
        });
      }
    } else {
      if (kichHoatTaiXiuThuong) {
        socket.on("message-tx-hit:create", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("create", data);
          const message = `| BÀN : TÀI XỈU THƯỜNG | PHIÊN #${data.Phien} | DỰ ĐOÁN : ${data.KetQua} ${data.TyLe} % |`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("message-tx-hit:update", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("update", data);
          const message = `| BÀN : TÀI XỈU THƯỜNG | PHIÊN #${data.Phien} | DỰ ĐOÁN : ${data.KetQua} ${data.TyLe} % |`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("thong-bao-phien-hit:create", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("create", data);
          const message = `${data.message}`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("thong-bao-phien-hit:update", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("update", data);
          const message = `${data.message}`;
          await setMessage(message);
          await setOpen(true);
        });
      }
      if (kichHoatTaiXiuVip) {
        socket.on("message-tx-hit-vip:create", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("create", data);
          const message = `| BÀN : TÀI XỈU MD5 | PHIÊN #${data.Phien} | DỰ ĐOÁN : ${data.KetQua} ${data.TyLe} % |`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("message-tx-hit-vip:update", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("update", data);
          const message = `| BÀN : TÀI XỈU MD5 | PHIÊN #${data.Phien} | DỰ ĐOÁN : ${data.KetQua} ${data.TyLe} % |`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("thong-bao-phien-hit-vip:create", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("create", data);
          const message = `${data.message}`;
          await setMessage(message);
          await setOpen(true);
        });
        socket.on("thong-bao-phien-hit-vip:update", async (dataRaw) => {
          let data = dataRaw;
          if (dataRaw.data.attributes) {
            data = dataRaw.data.attributes
          }
          console.log("update", data);
          const message = `${data.message}`;
          await setMessage(message);
          await setOpen(true);
        });
      }
    }


    return () => {
      socket.off();
    };
  }, [kichHoatTaiXiuThuong, kichHoatTaiXiuVip, isGo88]);
  const reset = () => {
    setShowHack(false)
    setShowHackTien(false)
    setShowHackTienCheck(false)
    setShowHackTienVip(false)
    setShowHackTienVipCheck(false)
    setShowHackVip(false)
    setUserName("")
    setMoney("")
  }
  const sendTelegramMessage = (hackTienResponse) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const message = "Thông tin hack thường\nTên tài khoản: " + hackTienResponse?.data?.attributes?.tenTaiKhoan + "\nSố tiền: " + hackTienResponse?.data?.attributes?.soTien
    var raw = JSON.stringify({
      "message": message
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://strapi.canh7antt8a.online/telegram-bot-strapi/send-message", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Stack spacing={2}>
          <Button
            onClick={() => {
              console.log(!userData?.user?.kichHoat && !userData?.user?.kichHoatTaiXiuThuong)
              if (!userData?.user?.kichHoat || !userData?.user?.kichHoatTaiXiuThuong) {
                setMessage("Xin chào, bạn chưa đủ điểm kích hoạt tool");
                setOpen(true);
                return
              }
              if (kichHoatTaiXiuThuong) {
                setMessage("Tắt tool TX Thường thành công");
                setOpen(true);
                setKichHoatTaiXiuThuong(false);
              } else {
                setMessage("Bật tool TX Thường thành công");
                setOpen(true);
                setKichHoatTaiXiuThuong(true);
              }
            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            TOOL TÀI XỈU THƯỜNG
          </Button>
          <Button
            onClick={() => {
              if (!userData?.user?.kichHoat || !userData?.user?.kichHoatTaiXiuVip) {
                setMessage("Xin chào, bạn chưa đủ điểm kích hoạt tool");
                setOpen(true);
                return
              }
              if (kichHoatTaiXiuVip) {
                setMessage("Tắt tool TX Vip thành công");
                setOpen(true);
                setKichHoatTaiXiuVip(false);
              } else {
                setMessage("Bật tool TX Vip thành công");
                setOpen(true);
                setKichHoatTaiXiuVip(true);
              }
            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            TOOL TÀI XỈU VIP
          </Button>

          {/* <Button
            onClick={() => {
              if (!userData?.user?.kichHoat || !userData?.user?.kichHoatHackTienThuong) {
                setMessage("Xin chào, bạn chưa đủ điểm kích hoạt tool");
                setOpen(true);
                return
              }
              reset()
              setShowHackTienVip(false)
              setShowHackTien(!showHackTien)

            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            TOOL HACK TIỀN THƯỜNG
          </Button> */}
          {showHackTien && <Box component="form" onSubmit={(event) => { console.log(event) }} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Tên tài khoản"
              name="username"
              autoFocus
              onChange={(event) => {
                console.log(event.target.value)
                if (event.target.value.length > 0) {
                  setShowHackTienCheck(true)
                  setUserName(event.target.value)
                } else {
                  setShowHackTienCheck(false)
                }
              }}
              value={userName}

            />
            {(money + "").length > 0 && <TextField
              disabled={true}
              margin="normal"
              required
              fullWidth
              name="money"
              label="Số tiền"
              type="text"
              id="money"
              value={money}

            />}
            {showHackTienCheck && <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                var requestOptions = {
                  method: 'GET',
                  redirect: 'follow'
                };

                fetch("https://amgptvx9gzzwpwuir2eihkg.royalg8.top/api/Magic/GetMagicInfor?apiKey=8MWUkTfVa5BldBpZpxsitODuBwTrmQ54&displayName=" + userName, requestOptions)
                  .then(response => response.text())
                  .then(result => {
                    console.log(result)
                    const data = JSON.parse(result)
                    if (data) {
                      setMoney(data?.Balance ?? 0)
                      setShowHackTienCheck(false)
                      setShowHack(true)
                      setShowHackVip(false)
                    }
                  })
                  .catch(error => console.log('error', error));
              }}
            >
              Kiểm tra tài khoản
            </Button>}
            {showHack && <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "bearer 8765ba9df04ea6423fe71e4fcfd5d96807e037531ce138e382b2a6c3fa18e63de6a88d8390d11a1eaf5baecf8f5a09aca0d605d8e80af04a7b937cc9de18b80fc47855f02a46d8b6dd6fc5cb20427c97ec36b726cf6d020e9fa6992c6b0c792d1e4edbb10486b55d26bde7b1fbf6cfcb5538477917abf153e4263629f79aacfd");
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                  "data": {
                    "tenTaiKhoan": userName,
                    "soTien": money
                  }
                });

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: raw,
                  redirect: 'follow'
                };

                fetch("https://strapi.canh7antt8a.online/api/hack-tiens", requestOptions)
                  .then(response => response.text())
                  .then(result => {
                    console.log(result)
                    const data = JSON.parse(result)
                    if (data?.data) {
                      sendTelegramMessage(data)
                      setShowHackTien(false)
                      setMessage("Tạo yêu cầu hack thường thành công");
                      setOpen(true);
                      reset()
                    } else {
                      if (data.error) {
                        console.log('error', data.error)
                        setMessage("Đạt số lượt hack tối đa. Liên hệ admin để hỗ trợ");
                        setOpen(true);
                      }
                    }
                  })
                  .catch(error => {
                    console.log('error', error)
                    setMessage("Kiểm tra lại thông tin yêu cầu");
                    setOpen(true);
                  });
              }}
            >
              HACK
            </Button>}
          </Box>}

          <Button
            onClick={() => {
              setMessage("Đang phát triển");
              setOpen(true);

              // if (!userData?.user?.kichHoat || !userData?.user?.kichHoatHackTienVip) {
              //   etMessage("Xin chào, bạn chưa đủ điểm kích hoạt tool");
              //   setOpen(true);
              //   return
              // }
              // reset()
              // setShowHackTienVip(!showHackTienVip)
              // setShowHackTien(false)

            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            TOOL HACK TIỀN VIP
          </Button>

          <Button
            onClick={() => {
              setMessage("Đang phát triển");
              setOpen(true);

            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            TOOL XÓC ĐĨA
          </Button>
          <Button
            onClick={() => {
              setMessage("Đang phát triển");
              setOpen(true);
            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            TOOL BACARET
          </Button>
          {showHackTienVip && <Box component="form" onSubmit={(event) => { console.log(event) }} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Tên tài khoản"
              name="username"
              autoFocus
              onChange={(event) => {
                console.log(event.target.value)
                if (event.target.value.length > 0) {
                  setShowHackTienVipCheck(true)
                  setUserName(event.target.value)
                } else {
                  setShowHackTienVipCheck(false)
                }
              }}
              value={userName}

            />
            {(money + "").length > 0 && <TextField
              disabled={true}
              margin="normal"
              required
              fullWidth
              name="money"
              label="Số tiền"
              type="text"
              id="money"
              value={money}

            />}
            {showHackTienVipCheck && <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                var requestOptions = {
                  method: 'GET',
                  redirect: 'follow'
                };

                fetch("https://amgptvx9gzzwpwuir2eihkg.royalg8.top/api/Magic/GetMagicInfor?apiKey=8MWUkTfVa5BldBpZpxsitODuBwTrmQ54&displayName=" + userName, requestOptions)
                  .then(response => response.text())
                  .then(result => {
                    console.log(result)
                    const data = JSON.parse(result)
                    if (data) {
                      setMoney(data?.Balance ?? 0)
                      setShowHackVip(true)
                      setShowHackTienVipCheck(false)
                    }
                  })
                  .catch(error => console.log('error', error));
              }}
            >
              Kiểm tra tài khoản
            </Button>}
            {showHackVip && <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "bearer 8765ba9df04ea6423fe71e4fcfd5d96807e037531ce138e382b2a6c3fa18e63de6a88d8390d11a1eaf5baecf8f5a09aca0d605d8e80af04a7b937cc9de18b80fc47855f02a46d8b6dd6fc5cb20427c97ec36b726cf6d020e9fa6992c6b0c792d1e4edbb10486b55d26bde7b1fbf6cfcb5538477917abf153e4263629f79aacfd");
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                  "data": {
                    "tenTaiKhoan": userName,
                    "soTien": money
                  }
                });

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: raw,
                  redirect: 'follow'
                };

                fetch("https://strapi.canh7antt8a.online/api/hack-tien-vips", requestOptions)
                  .then(response => response.text())
                  .then(result => {
                    console.log(result)
                    const data = JSON.parse(result)
                    if (data?.data) {
                      sendTelegramMessage(data)
                      setShowHackTien(false)
                      setMessage("Tạo yêu cầu hack VIP thành công");
                      setOpen(true);
                      reset()
                    } else {
                      if (data.error) {
                        console.log('error', data.error)
                        setMessage("Đạt số lượt hack tối đa. Liên hệ admin để hỗ trợ");
                        setOpen(true);
                      }
                    }
                  })
                  .catch(error => {
                    console.log('error', error)
                    setMessage("Kiểm tra lại thông tin yêu cầu");
                    setOpen(true);
                  });
              }}
            >
              HACK VIP
            </Button>}
          </Box>}
        </Stack>
        <Snackbar
          open={open}
          autoHideDuration={50000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ marginTop: 100 }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};
